import React, { useState } from 'react';

export const RoomContext = React.createContext({
  roomCreator: false,
  setRoomCreator: () => {},
});

const RoomContextProvider = ({ children }) => {
  const [roomCreator, setRoomCreator] = useState(false);

  return (
    <RoomContext.Provider value={{ roomCreator: roomCreator, setRoomCreator: setRoomCreator }}>
      {children}
    </RoomContext.Provider>
  );
};

export default RoomContextProvider;