import React, { useState } from "react"

export const ThemeContext = React.createContext({
  theme: "",
  setTheme: () => {},
})
// options: 'dark', 'light', 'planet'
const ThemeContextProvider = ({ children }) => {
  const [theme, setTheme] = useState("planet")

  return (
    <ThemeContext.Provider value={{ theme: theme, setTheme: setTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeContextProvider
